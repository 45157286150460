<template>
  <div class="contact-wrapper">
    <div class="map">
      <div class="text">
        <div>
          <div class="title">{{ $t('contactUs') }}</div>
          <div class="list">
            <div>{{ $t('phonenamme') }}: {{ $t('phoneDetails') }}</div>
            <div>{{ $t('email') }}：info@ewinlight.com</div>
            <div>{{ $t('addressname') }}: {{ $t('addressDetails') }}</div>
            <div>{{ $t('wordTime') }}: {{ $t('wordTimeDetails') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="email">
      <div class="title">
        {{ $t('email') }}
      </div>
      <div class="email-form">
        <div class="email-form-item-wrapper">
          <div
            v-for="item in formArray"
            :key="item"
            class="email-form-item"
            :class="item === 'message' ? 'email-form-item-message' : ''"
          >
            <div class="left">
              <img :src="require('@/assets/image/about/'+ item +'.png')" />
            </div>
            <div class="right">
              <div class="input">
                <input
                  v-if="item  !== 'message'"
                  v-model="form[item]"
                  class="email-form-input"
                  type="text"
                  :placeholder="$t('pleaseEnter') + $t(item)"
                />
                <textarea 
                  v-else
                  v-model="form[item]"
                  class="email-form-input"
                  rows="5"
                  :placeholder="$t('pleaseEnter') + $t(item)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="codeIndex >= 0"
          class="email-form-submit">
          <div class="left">
            <div>{{$t('pleaseEnterTrue')}}: {{ codeArray[codeIndex].question }}?</div>
            <div class="email-form-item">
              <input
                  v-model="codeAnswer"
                  :placeholder="$t('pleaseEnterTrue')"
                />
            </div>
          </div>
          <div class="right">
            <button @click="reset">{{ $t('reset') }}</button>
            <button @click="submit">{{ $t('submit') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goPost, showMessage } from '@/utils'
export default {
  name: 'Contact',
   metaInfo() {
    return {
      title: this.$t('contactUs')
    }
  },
  data() {
    return {
      codeArray: [
        {
          question: '6 + 1',
          answer: '7'
        },
        {
          question: '7 + 1',
          answer: '8'
        },
        {
          question: '9 + 0',
          answer: '9'
        },
        {
          question: '11 - 1',
          answer: '10'
        },
        {
          question: '2 + 4',
          answer: '6'
        },
        {
          question: '10 - 9',
          answer: '1'
        },
        {
          question: '1 + 1',
          answer: '2'
        },
        {
          question: '5 - 2',
          answer: '3'
        },
        {
          question: '2 * 2',
          answer: '4'
        },
        {
          question: '10 / 2',
          answer: '5'
        }
      ],
      formArray: ['theme', 'user_name', 'address', 'phone', 'message'],
      form: {
        theme: '',
        phone: '',
        user_name: '',
        address: '',
        message: ''
      },
      formDeep: {},
      codeIndex: -1,
      codeAnswer: ''
    }
  },
  mounted() {
    this.formDeep = Object.assign({}, this.form)
    this.codeIndex = this.randomNum()
  },
  methods: {
    randomNum() {
      return Math.floor(Math.random()*10)
    },
    reset() {
      this.form = Object.assign({}, this.formDeep)
    },
    async submit() {
      if (this.codeAnswer !== this.codeArray[this.codeIndex].answer) {
        showMessage(this.$t('pleaseEnterTrue'), 'error')
        this.codeIndex = this.randomNum()
        this.codeAnswer = ''
        return false
      }
      const {theme, user_name, address, message} = this.form
      if (theme && user_name && address && message) {
        const result = await goPost('/ewinlight/email', this.form, '#app')
        if (result.code === 200) {
          showMessage(this.$t('success'), 'success')
        } else {
          showMessage(result.msg, 'error')
        }
        this.codeIndex = this.randomNum()
      } else {
        showMessage(this.$t('pleaseEnterTrue') + this.$t('theme') + ',' + this.$t('user_name') + ',' + this.$t('address') + ',' + this.$t('message'), 'error')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-wrapper {
  overflow: hidden;
  background: rgba(245, 246, 247, 1);
  padding: 20px;
  padding-top: 0;
  .map {
    width: 100%;
    overflow: hidden;
    position: relative;
    .text {
      &>div{
        &>.title {
          color: rgba(51, 51, 51, 1);
          font-size: 20px;
          font-weight: bold;
          line-height: 36px;
          text-align: center;
        }
        &>.list {
          color: rgba(51, 51, 51, 1);
          font-size: 13px;
          line-height: 20px;
          background: #fff;
          margin-top: 12px;
          border-radius: 12px;
          padding: 18px;
          & > div {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .email {
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      margin: 20px 0;
    }
    .email-form {
      .email-form-item-wrapper {
        background: #fff;
        padding: 20px;
        padding-top: 0;
        border-radius: 12px;
        overflow: hidden;
        .email-form-item {
          display: flex;
          width: 100%;
          height: 57px;
          border-bottom: 1px solid #E3E3E3;
          padding-top: 4px;
          .left {
            width: 30px;
            height: 30px;
            margin-right: 13px;
            flex-shrink: 0;
            img {
              width: 100%;
            }
          }
          .right {
            width: 252px;
            height: 30px;
            display: flex;
            align-items: center;
            .email-form-input {
              border: 0;
              outline: none;
              width: 252px;
              height: 30px;
            }
          }
        }
        .email-form-item-message {
          width: 100%;
          align-items: flex-start;
          height: 200px;
          .right {
            height: 200px;
            padding: 10px 0;
            textarea {
              height: 180px !important;
              line-height: 30px;
              width: 100%;
              font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
            }
          }
        }
      }
      .email-form-submit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px;
        padding-top: 0;
        .left {
          width: 150px;
          border-bottom: 1px solid rgba(227, 227, 227, 1);
          &>div:nth-child(1) {
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            line-height: 32px;
          }
          input {
            border: 0;
            outline: none;
            width: 100%;
          }
        }
        .right {
          button {
            width: 60px;
            height: 32px;
            line-height: 32px;
            color: rgba(255, 255, 255, 1);
            overflow: hidden;
            border: 0;
            outline: none;
            margin-left: 10px;
            font-size: 14px;
            &:nth-child(1) {
              margin-left: 0;
            }
            cursor: pointer;
            &:nth-child(1) {
              background: rgba(170, 170, 170, 1);
            }
            &:nth-child(2) {
              background: rgba(51, 51, 51, 1);
            }
          }
        }
      }
    }
  }

}
</style>